<script setup lang="ts">
import { whatsAppLink } from '@/modules/core/constants'
import { TestABGroups, UserIdentifiersEvent } from '@/modules/core/enums'
import { EventName } from '@/modules/trevo/types/enum'
import { useVuelidate } from '@vuelidate/core'

defineProps({
  link: {
    type: String,
    required: false,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
})

const { $api, $validators } = useNuxtApp()

const hideWhatsapp = computed<boolean>(() => abTest() === TestABGroups.HIDE_WHATSAPP)

interface IForm {
  name: string
  phone: string
}

const user = reactive<IForm>({
  name: '',
  phone: '',
})

const rules = computed(() => ({
  name: {
    required: $validators.required(),
    minLength: $validators.size({
      size: 2,
      message: 'Informe no mínimo 4 caracteres',
    }),
  },
  phone: {
    required: $validators.required(),
    size: $validators.size({ size: 19, message: 'Telefone incompleto' }),
  },
}))

const validation = useVuelidate(rules, user)

const showMessage = ref(true)
const openedModal = ref(false)

const hasHovered = ref(false)

function handleHover() {
  if (!hasHovered.value) {
    showMessage.value = true
    hasHovered.value = true
  }
}

function closeMessage() {
  showMessage.value = false
  hasHovered.value = false
}

function toggleModal() {
  openedModal.value = !openedModal.value

  showMessage.value = false

  if (openedModal.value)
    track(EventName.OpenWppModal)
}

async function createUserButton() {
  if (validation.value.$invalid)
    return

  const nameParts = user.name.split(' ')
  const firstName = nameParts.shift()
  const lastName = nameParts.join(' ')

  const formattedPhone = user.phone.replace(/\D/g, '')

  const payload = {
    name: firstName,
    lastName,
    phone: formattedPhone,
    deviceId: useTrackMixpanelDistinctId().value,
    sessionId: useTrackGoogleSessionId().value,
    userIdentifiers: {
      userPseudoId: useTrackGoogleClientId().value,
      event: UserIdentifiersEvent.CHAT_INTERACTION,
      gclid: getGclid(),
      deviceId: useTrackMixpanelDistinctId().value,
      sessionId: useTrackGoogleSessionId().value,
    },
  }

  track(EventName.InitWppConversation, {
    name: payload.name,
    phone: payload.phone,
  })

  await $api.recommendations.chatInteraction(payload)

  openedModal.value = false
}

function openWppLink() {
  window.location.href = whatsAppLink
}

async function handleClickSendMessage() {
  if (validation.value.$invalid)
    return
  await createUserButton()
  openWppLink()
}

onNuxtReady(() => {
  setTimeout(() => {
    showMessage.value = false
  }, 3000)
})
</script>

<template>
  <div v-if="!hideWhatsapp" id="trevo-whatsapp-button" class="fixed bottom-10 lg:bottom-8 right-4 lg:right-8 z-20">
    <div class="relative w-[50px] h-[50px]">
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight animate__faster"
        leave-active-class="animate__animated animate__fadeOutRight animate__faster"
        mode="out-in"
      >
        <div
          v-show="showMessage"
          class="max-w-[280px] w-[280px] sm:max-w-[357px] sm:w-[357px] p-6 bg-primary-95 shadow-lg rounded-lg absolute -top-24 right-4"
        >
          <button
            aria-label="Botão para fechar a mensagem"
            class="flex items-center justify-center w-6 h-6 lg:w-10 lg:h-10 rounded-full bg-primary-50 absolute -top-3 -right-3 lg:-top-5 lg:-right-5"
            type="button"
            @click="closeMessage"
          >
            <Icon
              name="ic:round-close"
              color="fff"
              class="w-3 h-3 lg:w-6 lg:h-6"
            />
          </button>
          <div
            class="flex items-center gap-4 text-[11px] sm:text-sm text-primary-10"
          >
            <Img
              classes="min-w-[16px] w-4 xs:min-w-[24px] lg:w-6"
              src="https://web-cdn.saudetrevo.com.br/trevo/logo_miniatura.svg"
              alt="Logo da trevo"
              width="24"
              height="24"
            />
            Estamos aqui para atender às suas necessidades de saúde!
          </div>
        </div>
      </transition>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight animate__faster"
        leave-active-class="animate__animated animate__fadeOutRight animate__faster"
        mode="out-in"
      >
        <form
          v-if="openedModal"
          class="w-[280px] sm:w-[357px] text-[11px] text-sm absolute -top-96 lg:-top-96 right-4 shadow-lg bg-white text-primary-10 rounded-lg"
        >
          <button
            aria-label="Botão para fechar ou abrir o modal de whatsapp"
            type="button"
            class="flex items-center justify-center w-6 h-6 lg:w-10 lg:h-10 rounded-full bg-primary-50 absolute -top-3 -right-3 lg:-top-5 lg:-right-5"
            @click.prevent="toggleModal"
          >
            <Icon
              name="ic:round-close"
              color="fff"
              class="w-3 h-3 lg:w-6 lg:h-6"
            />
          </button>
          <div class="px-3 pt-6">
            <span class="block"> Olá! Que tal aproveitar um orçamento </span>
            <span class="block"> gratuito para seus exames? </span>
            <span class="block"> Para iniciarmos uma conversa sem </span>
            <span class="block"> compromisso, por favor, compartilhe seu </span>
            <span class="block"> nome e telefone. </span>
          </div>
          <div class="pt-2 flex flex-col gap-2 px-3">
            <TInputText
              id="name"
              v-model="user.name"
              label="Nome"
              class="mt-2"
              :max="80"
              :validation="validation.name"
              @change="validation.name.$touch"
            />

            <TInputText
              id="phone"
              v-model="user.phone"
              class="mt-2"
              label="Telefone"
              mask="+55 (##) 9####-####"
              :max="19"
              :validation="validation.phone"
              @change="validation.phone.$touch"
            />
          </div>
          <!-- <p v-if="validation.$invalid" class="text-xs text-error-50 text-center pt-2">
              Por favor, verifique as informações e tente novamente.
            </p> -->
          <div
            class="flex items-center justify-center gap-2 px-2 py-3 text-[9px] sm:text-sm"
          >
            <button
              class="rounded-lg bg-primary-50 text-white px-1 py-2 w-full text-center"
              type="button"
              @click.prevent="handleClickSendMessage"
            >
              Iniciar conversa
            </button>
            <!-- <button
                class="rounded-lg border border-neutral-60 text-primary-50 px-1 py-2 w-full"
                type="button"
                @click.prevent="handleClickCallLatter"
              >
                Falar mais tarde
              </button> -->
          </div>
        </form>
      </transition>

      <button
        aria-label="Botão para entrar em contato pelo WhatsApp"
        class="flex items-center justify-center min-w-[50px] min-h-[50px] rounded-full bg-green-partner text-white hover:scale-110 duration-300"
        type="button"
        @mouseover="handleHover"
        @click="toggleModal"
      >
        <span class="sr-only">Botão para entrar em contato pelo WhatsApp</span>
        <Icon name="ic:round-whatsapp" color="currentColor" class="w-6 h-6" />
        <span>{{ message }}</span>
      </button>
    </div>
  </div>
</template>
